<template>
  <div class="author-detail ct-home ml-3 pt-3" v-if="detailNew">
    <div>
      <div class="row d-flex align-items-center mb-4">
        <div class="col-md-2 col-12">
          <span>{{
            moment(String(detailNew.post_date)).format("YYYY/MM/DD")
          }}</span>
        </div>
        <div class="col-md-3 col-6">
          <label
            variant="warning"
            class="w-100 btn-news"
            style="
              background-color: yellow;
              text-align: center;
              color: #7f7f7f;
              border: 2px solid #7f7f7f;
              border-radius: 5px;
            "
          >
            {{ detailNew.news_title }}
          </label>
        </div>
      </div>
    </div>
    <p v-html="detailNew.news_content"></p>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Constants } from "../../utils/constants";
import moment from "moment";
export default {
  name: "DetailNew",
  data() {
    return {
      shop_id: localStorage.getItem(Constants.SHOP_ID),
    };
  },
  components: {
    // VueSlickCarousel,
  },
  created() {
    const { params } = this.$route;
    const { id } = params;
    const request = {
      id: id,
      shop_id: this.shop_id,
      user_type: localStorage.getItem(Constants.USER_TYPE_USER),
    };
    this.getNewByIdUser(request);
  },
  watch: {
    detailNew() {
      if (this.detailNew) {
        if (
          (this.detailNew.windows_hover_type === 0 &&
            this.detailNew.countdown_date <
              moment(new Date()).format("YYYY-MM-DD HH:mm")) ||
          (this.detailNew.windows_hover_type === 1 &&
            this.detailNew.time_read >
              this.detailNew.countdown_time_read * 1) ||
          (this.detailNew.windows_hover_type === 2 &&
            this.detailNew.time_read > 1)
        ) {
          window.location.href = this.detailNew.countdown_url;
        }
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
  },
  computed: {
    ...mapGetters(["detailNew", "error", "message"]),
  },
  methods: {
    ...mapActions({ getNewByIdUser: "getNewByIdUser" }),
    moment,
  },
};
</script>
